.mt5 {
  margin-top: 5px !important;
}

.mt10 {
  margin-top: 10px !important;
}

.mt15 {
  margin-top: 15px !important;
}

.mt20 {
  margin-top: 20px !important;
}

.mt25 {
  margin-top: 25px !important;
}

.mt30 {
  margin-top: 30px !important;
}

.mt35 {
  margin-top: 35px !important;
}

.mt40 {
  margin-top: 40px !important;
}

.mt45 {
  margin-top: 45px !important;
}

.mt50 {
  margin-top: 50px !important;
}

.mt55 {
  margin-top: 55px !important;
}

.mt60 {
  margin-top: 60px !important;
}

.mt90 {
  margin-top: 90px !important;
}

.mb8 {
  margin-bottom: 8px !important;
}


.mb10 {
  margin-bottom: 10px !important;
}

.mb15 {
  margin-bottom: 15px !important;
}

.mb16 {
  margin-bottom: 16px !important;
}

.mb20 {
  margin-bottom: 20px !important;
}

.mb25 {
  margin-bottom: 25px !important;
}

.mb30 {
  margin-bottom: 30px !important;
}

.mb32 {
  margin-bottom: 32px !important;
}

.mb35 {
  margin-bottom: 35px !important;
}

.mb40 {
  margin-bottom: 40px !important;
}

.mb45 {
  margin-bottom: 45px !important;
}

.mb50 {
  margin-bottom: 50px !important;
}

.mr15 {
  margin-right: 15px !important;
}

.ml1 {
  margin-left: 1px !important;
}

.ml5 {
  margin-left: 5px !important;
}

.ml3 {
  margin-left: 3px !important;
}

.ml5 {
  margin-left: 5px !important;
}

.ml10 {
  margin-left: 10px !important;
}

.ml15 {
  margin-left: 15px !important;
}

.pl0 {
  padding-left: 0 !important;
}

.justify-content-end {
  justify-content: end;
}

.bold {
  font-weight: bold !important;
}

.no-border {
  border: 0;
}

.darkblue-font {
  color: #1B365D;
}

.blue-font {
  color: #0063CF;
}

.light-blue-font {
  color: #FF9E1B;
}

.light-blue-font {
  color: #5B7F95;
}

.white-font {
  color: #fff;
}

.black-font {
  color: #000;
}

.fs11 {
  font-size: 11px;
}

.fs12 {
  font-size: 12px;
}

.fs13 {
  font-size: 13px;
}

.fs14 {
  font-size: 14px;
}

.fs15 {
  font-size: 15px;
}

.fs16 {
  font-size: 16px;
}

.fs17 {
  font-size: 17px;
}

.fs18 {
  font-size: 18px;
}

.fs19 {
  font-size: 19px;
}

.fs20 {
  font-size: 20px;
}

.fs21 {
  font-size: 22px;
}

.fs22 {
  font-size: 22px;
}

.fs23 {
  font-size: 23px;
}

.fs24 {
  font-size: 24px;
}

.fs25 {
  font-size: 25px;
}

.fs26 {
  font-size: 26px;
}

.fs27 {
  font-size: 27px;
}

.fs28 {
  font-size: 28px;
}

.uppercase {

}

.no-bg {
  background: 0;
}

.orange-gradient-bg {
  background: #fd7d00;
  background: linear-gradient(to right, #fd7d00 0%, #228BF0 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fd7d00', endColorstr='#228BF0', GradientType=0);
}

.blue-gradient-bg {
  background: #426687;
  background: -moz-linear-gradient(left, #426687 0%, #66839e 100%);
  background: -webkit-linear-gradient(left, #426687 0%, #66839e 100%);
  background: linear-gradient(to right, #426687 0%, #66839e 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#426687', endColorstr='#66839e', GradientType=0);
}

.darkblue-gradient-bg {
  background: #0063CF !important;
  min-width: 64px;
  box-sizing: border-box;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-family: RobotoBold;
  font-weight: 500;
  line-height: 1rem;
  border-radius: 4px;
  letter-spacing: 0.02857em;
  text-transform: none;
  color: #FFFFFF !important;
  padding: 7px 15px;
}

.darkblue-gradient-bg:hover {
  background-color: darken(#0063CF, 15) !important;
  color: #FFFFFF !important;
}

.darkblue-gradient-bg2 {
  background: lighten(#0063CF, 30%) !important;
  min-width: 64px;
  box-sizing: border-box;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-family: RobotoBold;
  font-weight: 500;
  line-height: 1rem;
  border-radius: 4px;
  letter-spacing: 0.02857em;
  text-transform: none;
  color: #FFF;
  padding: 7px 15px;
}

.darkblue-gradient-bg2:hover {
  background-color: #0063CF !important;
  color: #FFF;
}

.flex-behaviour {
  display: flex;
}

.flex-align-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-align-center-column {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
}

.display-flex {
  display: flex;
}

.relative-pos {
  position: relative;
}

.gotham-bold {
  font-family: RobotoBold !important;
}

.gotham-medium {
  font-family: RobotoMedium !important;
}

.hurme {
  font-family: Roboto !important;
}

.hide {
  display: none;
}

.cursor-pointer {
  cursor: pointer;
}

.w100 {
  width: 100%;
}

.error-iclinic {
  background: #FFEBEE;
  color: #455A64;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.444444px;
}

.warning-iclinic {
  background: #FFF8E1;
  color: #455A64;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.444444px;
}

.info-iclinic {
  background: #eaf3f5;
  color: #455A64;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.444444px;
}

.success-iclinic {
  background: #E8F5E9;
  color: #455A64;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.444444px;
}

.btn-white-blue {
  background-image: linear-gradient(to bottom, #fff, #fafafa);
  border-color: #e6e6e6 !important;
  box-shadow: inset 0 1px 0 0 #fff, 0 1px 0 0 rgb(22 29 37 / 5%), 0 0 0 0 transparent;
  color: #2c97d1 !important;
  background-color: #fff !important;
}
