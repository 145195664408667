/* You can add global styles to this file, and also import other style files */

@import "@angular/material/prebuilt-themes/indigo-pink.css";
@import "../node_modules/angular-calendar/css/angular-calendar.css";
@import '~material-design-icons/iconfont/material-icons.css';
@import "~@ng-select/ng-select/themes/default.theme.css";
@import 'app/shared/styles/toastr';

@font-face {
  font-family: 'Roboto Mono';
  src: local('Roboto Mono'), url(../src/assets/fonts/RobotoMono-Regular.ttf) format('truetype');
}

body {
    color: #455A64;
}

a:hover, a:focus, a:active {
    text-decoration: none;
}

select {
    cursor: pointer;
}

button:hover, button:focus, button:active {
    outline: 0;
    box-shadow: 0;
}

p, h1, h2, h3, h4, h5 {
    margin: 0;
}

strong {
    font-family: RobotoBold !important;
    font-weight: normal !important;
}

@media (min-width: 1370px) {
    .container {
        max-width: 100%;
    }
}

@media (min-width: 1200px) {
    .container {
        max-width: 100%;
    }
}

.tooltip-question-mark img, .dashboard-question-mark img {
    filter: grayscale(100%);
    transition: .2s;
}

.tooltip-question-mark:hover img, .dashboard-question-mark:hover img  {
    filter: grayscale(0);
}

.tooltip {
    opacity: 1;
}

.system-tooltip {
    vertical-align: 1px !important;
    padding-right: 20px;
}

.tooltip-question-mark img {
    vertical-align: -2px;
    padding-right: 20px;
}

// Specific tooltip styles

.anticipation-title-tip .tooltip-inner {
    background: #333 !important;
    color: #fff;
    max-width: 360px;
    padding-top: 5px;
    font-size: 14px;
    text-align: inherit;
    letter-spacing: 1px;
    font-style: italic;
}

.dashboard-question-mark {
    top: 2px;
    position: relative;
}


// Calendar Overrides
.cal-month-view .cal-day-cell:not(:last-child) {
    border: 0;
}

.cal-month-view {
    background: #eaf3f5;
    padding: 55px 10px 15px;
}

.cal-month-view .cal-days {
    border: 0;
}

.cal-month-view .cal-days .cal-cell-row {
    border: 0;
}

.cal-month-view .cal-day-cell:not(:last-child) {
    border-radius: 50%;
}

.cal-month-view .cal-day-badge {
    display: none;
}

.cal-month-view .cal-cell-top {
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
}

.cal-month-view .cal-day-number {
    margin-right: 0;
}

.cal-month-view .cal-day-cell.cal-today {
    background: 0;
}

.cal-month-view .cal-cell-row .cal-cell:hover {
    background: 0;
}

.cal-month-view .cal-day-cell.cal-in-month.cal-has-events {
    background: 0 !important;
    position: relative;

    .cal-day-number {
        color: #fff;
    }
}

.cal-month-view .cal-day-cell.cal-today .cal-day-number {
    font-size: 1.2rem;
    color: #0063CF;
}

.cal-month-view .cal-events {
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    margin: 0 auto;
}

.cal-month-view .cal-event {
    width: 45px;
    height: 45px;
}

.cal-month-view .cal-day-number {
    color: #1b365d;
    font-weight: bold;
    opacity: 1;
}

.cal-month-view .cal-day-cell.cal-out-month .cal-day-number {
    opacity: 0.6;
    cursor: default;
    color: #5b7f95;
}

.cal-month-view .cal-day-cell.cal-weekend .cal-day-number {
    color: inherit;
}

.cal-month-view .cal-event {
    display: flex;
    align-items: center;
    justify-content: center;
}

.cal-tooltip-inner {
    background: #1b365d;
    font-size: 20px;
    font-weight: bold;
    padding: 3px 13px;
}

.cal-tooltip.cal-tooltip-top .cal-tooltip-arrow {
    border-top-color: #1b364d;
}
.fa.fa-angle-down.fa-angle-up.caret.ng-star-inserted {
  color: #86B1DF !important;
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.table-card {

  .table-card-head{
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;

    color: #607D8B;

    margin-bottom: 16px;
  }

  .table-card-body{
    border: 1px solid #ECEFF1;
    border-radius: 4px;
    height: 48px;

    display: flex;
    align-content: center;
    margin-bottom: 8px;
    transition: .2s;

    .checkmark{
      border: 2px solid #90A4AE;
      border-radius: 2px;
      top: 2px;
    }

    .checkmark:hover{
      background: #F4F9FE;
    }

    .checkbox-container input:checked ~ .checkmark{
      background: #0063CF;
      border: 2px solid #0063CF;
    }

    .checkbox-container .checkmark::after{
      top: -1px;
      left: 3px;
      width: 8px;
      height: 13px;
      border: solid white;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
    }

    .checkmark::after{
      content: "";
      position: absolute;
      display: none;
    }

    .checkbox-container input:checked ~.checkmark::after{
      display: block;
    }

    .button-see-details{
      position: relative;
      display: flex;
      align-items: center;
      text-align: center;

      button{
        position: absolute;
        top: 2px;
        right: 35px;

        background: none;
        width: 20px;
        display: flex;
        justify-content: center;
      }

      .text-see-details {
        position: absolute;
        top: -30px;
        right: 8px;
        z-index: 10;

        background: #455A64;
        border-radius: 4px;
        padding: 4px;
        width: 81px;

        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        color: #F8F9FA;

        opacity: 0;
        visibility: hidden;
        -webkit-transition: opacity 0.2s linear;
        -moz-transition: opacity 0.2s linear;
        -o-transition: opacity 0.2s linear;
        transition: opacity 0.2s linear;
      }

      .ballon{
        position: absolute;
        top: -9px;
        right: 44px;
        z-index: 0;

        width: 6px;
        height: 6px;
        border: solid #455A64;
        background: #455A64;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);

        opacity: 0;
        visibility: hidden;
        -webkit-transition: opacity 0.2s linear;
        -moz-transition: opacity 0.2s linear;
        -o-transition: opacity 0.2s linear;
        transition: opacity 0.2s linear;
      }

      button:hover ~ .text-see-details, button:hover ~ .ballon {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  .table-card-body:hover{
    border: 1px solid #0063CF;
    background: #F4F9FE;
  }
}

.ng-select-container {
    border: 0 !important;
}

.ng-arrow {
  display: none !important;
}

.btn-drawer {
  padding: 16px;
  width: 114px;
  height: 56px;
  font-family: RobotoBold, serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.3px;
  color: #F8F9FA;
  background-color: #0063CF;
  border-color: #0063CF;
  border-radius: 4px;
  margin-block: 20px;
}

.btn-drawer-light {
  padding: 16px;
  font-family: RobotoBold, serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.3px;
  color: #003F72;
  background-color: #D9EBFC;
  border-color: #D9EBFC;
  border-radius: 4px;
  margin-top: 20px;

  .material-icons {
    vertical-align: middle;
    font-size: 18px;
  }
}

.btn-drawer-light:hover {
  background-color: darken(#D9EBFC, 15%);
  border-color: darken(#D9EBFC, 15%);
}

.btn-drawer-disabled {
  padding: 16px;
  width: 114px;
  height: 56px;
  font-family: RobotoBold, serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.3px;
  color: #F8F9FA;
  background-color: #0063CF;
  border-color: #0063CF;
  border-radius: 4px;
  margin-top: 20px;
  opacity: 0.32;
  cursor: alias;
}

.error-message {
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  letter-spacing: 0.444444px;
  color: #E53935;
  flex: none;
  order: 0;
  flex-grow: 1;
  margin: 5px 0 0 15px;
}

.ng-value-container {
  padding-top: 10px !important;
  padding-bottom: 6px;
  overflow-x: auto;
}
