@font-face {
  font-family: Roboto;
  src: url('/assets/fonts/hurme-geometric.otf');
}

@font-face {
  font-family: RobotoMedium;
  src: url('/assets/fonts/gotham-narrow-medium.otf');
}

@font-face {
  font-family: RobotoBook;
  src: url('/assets/fonts/gotham-narrow-book.otf');
}

@font-face {
  font-family: RobotoBold;
  src: url('/assets/fonts/gotham-narrow-bold.otf');
}

@font-face {
  font-family: Roboto;
  src: url('/assets/fonts/Roboto-Regular.ttf');
}

@font-face {
  font-family: RobotoBold;
  src: url('/assets/fonts/Roboto-Bold.ttf');
}

@font-face {
  font-family: RobotoMedium;
  src: url('/assets/fonts/Roboto-Medium.ttf');
}

$font-family: Roboto;
$font-hurme: Roboto;
$font-gotham-bold: RobotoBold;
$font-gotham-medium: RobotoMedium;

$resXXL: 1280px;
$resXL: 1170px;
$resL: 991px;
$resM: 768px;
$resS: 660px;
$resXS: 500px;
$resXXS: 435px;
$resMin: 320px;

$top-height: 0;
$sidebar-width: 300px;

$small-card-height: 114px;
$xsmall-card-height: 187px;
$medium-card-height: 400px;
$extra-medium-card-height: 550px;
$large-card-height: 974px;

$default-animation-duration: 0.2s;
$default-animation-style: ease-out;

$assets-root: '/assets';
$images-root: '/img/';
$fonts-root: '/fonts/';
$font-thin: 100;
$font-light: 300;
$font-normal: 400;
$font-bold: 700;
$font-bolder: 500;
$font-ultraBold: 900;

$facebook-color: #3b5998;
$twitter-color: #55acee;
$google-color: #dd4b39;
$linkedin-color: #0177B5;
$github-color: #6b6b6b;
$stackoverflow-color: #2F96E8;
$dribble-color: #F26798;
$behace-color: #0093FA;

$azul-medicinae: #003F72;
$laranja-medicinae: #0063CF;
$azul-iclinic: #0063CF;
$azulClaro-medicinae: #228BF0;
$menu-blue: #e1e6ec;
