@import 'conf/conf';

$left-space: $sidebar-width;

@include scrollbars(.5em, #d9d9d9, rgba(0, 0, 0, 0));

body {
    font-family: $font-family !important;
}

button.close {
    padding-bottom: 2px;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: .2;
    top: -3px;
    position: relative;
}

.maintenance-alert {
    color: #ad2121;
    background-color: #FDF1BA;
    padding: 16px;
    margin: 0 12px;
}

.app-content {
    padding: 10px 0 40px;
}

a {
    cursor: pointer;
}

.app-main {
    padding-inline: 350px 50px;
    padding-top: 80px;
    transition: .2s;

    h1 {
        color: #455A64;
        font-weight: bold;
        font-size: 22px;
        padding: 4px 0;
        font-family: $font-gotham-bold;
    }
}

.app-main.menuCollapsed {
    padding-inline: 100px 44px;
}

.app-main.noMenu {
    padding-left: 0 !important;
}

ng2-completer {
    padding: 0 !important;
    padding-left: 15px !important;
}

ng2-completer .completer-holder > input {
    background: 0;
    width: 100%;
    border: 0;
    height: 35px;
    color: #464a4c;
}

ng2-completer .completer-holder > input:focus {
    outline: none !important;
}

@media all and (max-width: 768px) {

    .email-messages {
      .email-not-confirmed-message {
        z-index: 10;
        padding-left: 60px;
      }
    }

    .app-main, .app-main.menuCollapsed {
        padding-inline: 16px;
    }

    .aviso-cadastro {
        padding: 5px 80px !important;
        font-size: 13px;
    }
}

.aviso-cadastro {
    position: fixed;
    bottom: 0;
    z-index: 9999;
    left: 0;
    right: 0;
    text-align: center;
    color: #fff;
    padding: 5px;
    letter-spacing: .2px;
}

button.orange-gradient-bg:hover, button.blue-gradient-bg:hover, button.darkblue-gradient-bg:hover, .cancel-btn:hover {
    //transform: scale(1.1);
}

select {
    font-family: Roboto !important;
}

.completer-dropdown {
    overflow: scroll;
    height: 300px;;
}

.email-confirmed-message {
    padding: 5px 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 9999;
}

.email-not-confirmed-message {
    padding: 5px 0;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 9999;
    left: 0;
}

.email-messages {
    position: relative;
}

contato > form > .row {
    transition: .2s;
}

conta > form > .row {
    transition: .2s;
}

.separator:hover + contato > form > .row {
    background: #f7f7f7;
}

.separator:hover + conta > form > .row {
    background: #f7f7f7;
}

body input:-webkit-autofill, body textarea:-webkit-autofill, body select:-webkit-autofill {
    -webkit-text-fill-color: #003F72 !important;
}

.auth-block.register, .auth-block.login  {
    .mat-form-field-infix {
        padding-bottom: 0;
    }

    .mat-form-field-label, .mat-form-field-invalid .mat-form-field-label {
        color: #455A64;
        padding-left: 10px;
    }

    .mat-form-field-underline, .mat-form-field-invalid .mat-form-field-ripple {
        background-color: #003F72;
        z-index: 3;
    }

  .mat-form-field-label-wrapper{
    font-size: 20px;
  }

  .mat-form-field-label{
    color: #455A64;
    font-size: 16px;
  }

    .input-group .form-control {
        width: 100%;
        padding-left: 7px;
        caret-color: #003F72;
        padding-top: 5px;
      margin-top: 5px;
    }

    .mat-form-field-infix {
        border: 0;
    }

    .input-group {
        margin-bottom: 20px;
    }

    .mat-form-field-wrapper {
        padding-bottom: 0;
    }

    .mat-form-field-underline {
        bottom: 0;
    }
}

// iPad portrait
@media only screen
and (min-device-width : 768px)
and (max-device-width : 1024px)
and (orientation : portrait) {
    conta > form > .row {
        display: block;
    }
}

#app-menu {
    .inactive {
        .svg-container {
            polygon, g, path, rect {
                fill: #99C1EC;
            }
        }
    }

    .inactive:hover {
        .svg-container {
            polygon, g, path, rect {
                fill: auto !important;
            }
        }
    }
}

.orange-alert {
    background: #0063CF;
    text-align: center;
    padding: 8px 0;
    font-size: 20px;
}

.lightblue-alert {
    background: #dde4e9;
    text-align: center;
    padding: 8px 0;
    font-size: 20px;
}

.center-me {
  margin: 0 auto;
}
.inline-block-center {
  text-align: center;
}
