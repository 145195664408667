$hover: 24;
.btn:focus, .btn:active:focus, .btn.active:focus,
.btn.focus, .btn:active.focus, .btn.active.focus {
  outline: none;
}

button {
  cursor: pointer;
  border: 0;
  line-height: 19px !important;
  position: relative;
  transition: .2s;
}

.btn {
  border-radius: 5px;
  transition: all 0.1s ease;
  padding: 0.344rem 1rem;
  font-size: 0.9rem;
}

.btn-clean {
  border: 0;
  background: 0;
}

.button-primary {
  cursor: pointer;
  display: block;
  width: 100%;
  height: 48px;
  color: white;
  background-color: $azul-iclinic;
  border: solid 1px $azul-iclinic;
  border-radius: 4px;

  &:hover {
    color: white;
    background-color: $azul-iclinic;
  }

  &.inverse {
    color: $azul-iclinic;
    background-color: white;

    &:hover {
      color: $azul-iclinic;
      background-color: white;
    }
  }

  &.m-progress {
    border-color: #ECEFF1 !important;
    background-color: #ECEFF1 !important;
  }

  &.disabled {
    cursor: default !important;
    border-color: #ECEFF1 !important;
    background-color: #ECEFF1 !important;
    color: #90A4AE !important;
  }
}

.without-border {
  border: none;
  outline: none;

  &:disabled {
    color: #90A4AE;
  }
}