@-webkit-keyframes spin {
  0% {
    transform: rotate(0deg); /* Firefox 16+, IE 10+, Opera */
  }
  100% {
    transform: rotate(360deg); /* Firefox 16+, IE 10+, Opera */
  }
}

@-moz-keyframes spin {
  0% {
    -moz-transform: rotate(0deg); /* Firefox 16+*/
  }
  100% {
    -moz-transform: rotate(360deg); /* Firefox 16+*/
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg); /* Firefox 16+, IE 10+, Opera */
  }
  100% {
    transform: rotate(360deg); /* Firefox 16+, IE 10+, Opera */
  }
}

#preloader  {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  z-index: 1003;
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#fc9535+0,f4b164+100 */
	background: linear-gradient(45deg, rgb(255, 206, 142), rgb(250, 176, 85), rgb(219, 191, 96));
    .loader-wrapper {
      display: flex;
      align-items: center;
      height: 100%;
      .logo {
        width: 100%;
        text-align: center;
      }
    }
}

$topDotColor: #F00;
$bottomDotColor: #777;
$dotSize: 6px;

*, *:before, *:after {
  margin: 0;
  padding: 0;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.spinner-container {
  display: inline-block;
  position: relative;
  top: 195px;

  img {
    display: block;
  }
}

// .dots {
//   display: inline-block;
//   position: relative;
//   vertical-align: middle;

//   &:not(:last-child){
//     margin-right: $dotSize*1.5;
//   }

//   &:before, &:after {
//     content: "";
//     display: inline-block;
//     width: $dotSize;
//     height: $dotSize;
//     border-radius: 50%;

//     position: absolute;
//   }

//   @for $i from 1 through 10 {
//     &:nth-child(#{$i}) {
//       &:before {
//         -webkit-transform: translateY(-200%);
//         transform: translateY(-200%);

//         -webkit-animation: animBefore 1s linear infinite;
//         animation: animBefore 1s linear infinite;

//         -webkit-animation-delay: $i*-0.9s;
//         animation-delay: $i*-0.9s;

//         background-color: $topDotColor;
//       }

//       &:after {
//         -webkit-transform: translateY(200%);
//         transform: translateY(200%);

//         -webkit-animation: animAfter 1s linear infinite;
//         animation: animAfter 1s linear infinite;

//         -webkit-animation-delay: $i*-0.9s;
//         animation-delay: $i*-0.9s;

//         background-color: $bottomDotColor;
//       }
//     }
//   }
// }

@-webkit-keyframes animBefore {
  0% {
    -webkit-transform: scale(1) translateY(-200%);
    z-index: 1;
  }

  25% {
    -webkit-transform: scale(1.3) translateY(0);
    z-index: 1;
  }

  50% {
    -webkit-transform: scale(1) translateY(200%);
    z-index: -1;
  }

  75% {
    -webkit-transform: scale(0.7) translateY(0);
    z-index: -1;
  }

  100% {
    -webkit-transform: scale(1) translateY(-200%);
    z-index: -1;
  }
}
@keyframes animBefore {
  0% {
    transform: scale(1) translateY(-200%);
    z-index: 1;
  }

  25% {
    transform: scale(1.3) translateY(0);
    z-index: 1;
  }

  50% {
    transform: scale(1) translateY(200%);
    z-index: -1;
  }

  75% {
    transform: scale(0.7) translateY(0);
    z-index: -1;
  }

  100% {
    transform: scale(1) translateY(-200%);
    z-index: -1;
  }
}
@-webkit-keyframes animAfter {
  0% {
    -webkit-transform: scale(1) translateY(200%);
    z-index: -1;
  }

  25% {
    -webkit-transform: scale(0.7) translateY(0);
    z-index: -1;
  }

  50% {
    -webkit-transform: scale(1) translateY(-200%);
    z-index: 1;
  }

  75% {
    -webkit-transform: scale(1.3) translateY(0);
    z-index: 1;
  }

  100% {
    -webkit-transform: scale(1) translateY(200%);
    z-index: 1;
  }
}
@keyframes animAfter {
  0% {
    transform: scale(1) translateY(200%);
    z-index: -1;
  }

  25% {
    transform: scale(0.7) translateY(0);
    z-index: -1;
  }

  50% {
    transform: scale(1) translateY(-200%);
    z-index: 1;
  }

  75% {
    transform: scale(1.3) translateY(0);
    z-index: 1;
  }

  100% {
    transform: scale(1) translateY(200%);
    z-index: 1;
  }
}

// CONTENT LOAD

#contentLoad {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 10;
  // background: rgba(2, 17, 35, 0.95);
  background: rgb(225, 230, 236);

  #contentContainer {
    position: fixed;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    margin: 0 auto;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
    padding-left: 100px;

    img {
      width: 165px;
      margin-bottom: 35px;
    }

    .loaded {
      span {
        display: inline-block;
        margin-left: 5px;
      }
    }

    p {
      // color: #fff;
      color: #374f6b;
      // font-weight: bold;

      span {
        color: #0063CF;
        margin-right: 5px;
        display: inline-block;
        font-weight: bold;
      }
    }

    > div {
      display: inline-block;
    }
  }
}

@media all and (max-width: 768px) {
  .spinner-container img {
      width: 100%;
  }

  #contentContainer {
    padding-left: 52px !important;

    p {
      max-width: 265px;
    }
  }
}
